// Parent Component (BillList.jsx)
import React, { useEffect } from "react";
import styled from "@emotion/styled";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Grid,
  Link,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PendingBills from "./PendingBills";
import { signal } from "@preact/signals-react";
import AllBills from "./AllBills";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

type RowType = {
  [key: string]: any;
  payeeId: string;
  payeeName: string;
  invoiceDate: string;
  billRefCode: string;
  paymentRefCode: string;
  billStatus: string;
  billAmount: number;
  amount: number;
  paymentStatus: string;
};
const bills = signal(null);

const BillList = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Helmet title="Bills" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Bills
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Bills</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      {/*       <Divider my={6} /> */}
      <Box sx={{ width: "100%", typography: "body1", marginTop: 10 }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Failed Payments" value="1" />
              <Tab label="All Bills" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <PendingBills />
          </TabPanel>
          <TabPanel value="2">
            <AllBills />
          </TabPanel>
        </TabContext>
      </Box>
    </React.Fragment>
  );
};

export default BillList;
