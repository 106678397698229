import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import LoginPage from "./features/Auth/Login";

interface AppProps {
  instance: PublicClientApplication;
  emotionCache?: any;
}

const clientSideEmotionCache = createEmotionCache();

const WrappedView = ({
  content,
  emotionCache,
}: {
  content: React.ReactNode;
  emotionCache: any;
}) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const { theme } = useTheme();
  return (
    <div className="App">
      <AuthenticatedTemplate>
        {activeAccount ? (
          <CacheProvider value={emotionCache}>
            <HelmetProvider>
              <Helmet
                titleTemplate="%s | CSI Pay"
                defaultTitle="CSIPay - Dashboard"
              />
              <Provider store={store}>
                {/* @ts-ignore */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MuiThemeProvider theme={createTheme(theme)}>
                    {content}
                  </MuiThemeProvider>
                </LocalizationProvider>
              </Provider>
            </HelmetProvider>
          </CacheProvider>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </div>
  );
};

export let msalInstance: PublicClientApplication;

const App = ({ instance, emotionCache = clientSideEmotionCache }: AppProps) => {
  msalInstance = instance; // Store the instance
  const content = useRoutes(routes);
  return (
    <MsalProvider instance={instance}>
      <WrappedView content={content} emotionCache={emotionCache} />
    </MsalProvider>
  );
};

export default App;
