import { Button, Box, Grid, Typography } from "@mui/material";
import MicrosoftLogo from "../../assets/images/Microsoft.png";
import CSIPAYLogo from "../../assets/images/CSI_PAY_Logo.png";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useEffect } from "react";
import { setSession } from "../../utils/jwt";
import { AuthError } from "@azure/msal-browser";

export default function LoginPage() {
  const { instance } = useMsal();
  const handleRedirect = async () => {
    instance.loginRedirect({
      ...loginRequest,
      prompt: "create",
    });
  };

  useEffect(() => {
    const getToken = async () => {
      try {
        const response = await instance.acquireTokenSilent({
          scopes: [process.env.REACT_APP_API_SCOPE].filter(
            (scope): scope is string => typeof scope === "string"
          ),
        });
        setSession(response.accessToken);
      } catch (error) {
        if (error instanceof AuthError) {
          console.error(
            "AuthError details:",
            error.errorCode,
            error.errorMessage
          );
        }
      }
    };

    const handleRedirectResult = async () => {
      try {
        await instance.handleRedirectPromise();
        await getToken();
      } catch (error) {
        console.error("Error handling redirect:", error);
      }
    };

    handleRedirectResult();
  }, [instance]);

  return (
    <Grid container direction="column" alignItems="center">
      <Typography variant="h2" color={"black"}>
        <img src={CSIPAYLogo} alt="CSI Pay logo" />
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Button
          sx={{
            fontFamily: "Segoe UI",
            fontSize: "15px",
            fontWeight: 600,
            color: "#ffffff",
            backgroundColor: "#2f2f2f",
            height: "41px",
            "&:hover": {
              backgroundColor: "#3f3f3f", // Change color on hover if desired
            },
          }}
          onClick={handleRedirect}
        >
          <img src={MicrosoftLogo} alt="Microsoft logo" /> &nbsp; Sign in with
          Microsoft
        </Button>
      </Box>
    </Grid>
  );
}
