import jwtDecode, { JwtPayload } from "jwt-decode";
import { verify, sign } from "jsonwebtoken";

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  console.log(timeLeft);
  expiredTimer = window.setTimeout(() => {
    console.log("expired");
  }, timeLeft);
};

const setSession = (accessToken: string | null) => {
  console.log("set token");
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    //axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    const decoded = jwtDecode<JwtPayload>(accessToken);
    if (decoded.exp) {
      handleTokenExpired(decoded.exp);
    }
  } else {
    localStorage.removeItem("accessToken");
    console.log("remove token");
    // delete axios.defaults.headers.common.Authorization;
  }
};

export { verify, sign, isValidToken, setSession };
