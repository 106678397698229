import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Paper as MuiPaper,
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  IconButton,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import agent from "../../utils/axios";
import LoadingButton from "@mui/lab/LoadingButton";
import { Payment1099 } from "../../types/payment1099";
import { pdfavailable } from "../../types/pdfavailable";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "Payee ID",
    width: 220,
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridCellParams) => (
      <Typography variant="body2">
        <Link
          component={NavLink}
          to={`/payees/detail/${params.value}?Status=Paid`}
          color={"black"}
        >
          {String(params.value)}
        </Link>
      </Typography>
    ),
  },
  { field: "payeeName", headerName: "Name", width: 250 },
  {
    field: "address",
    headerName: "Address",
    width: 400,
    renderCell: (params: GridCellParams) => (
      <Typography variant="body2">
        {`${params.row.address1}${
          params.row.address2 ? `, ${params.row.address2}` : ""
        }, ${params.row.city}, ${params.row.state} ${params.row.postalCode}`}
      </Typography>
    ),
  },

  {
    field: "amount",
    headerName: "Amount",
    renderCell: (params: GridCellParams) => (
      <Typography variant="body2">
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params.value as number)}
      </Typography>
    ),
  },
  {
    field: "taxId",
    headerName: "Tax ID",
    renderCell: (params: GridCellParams) => (
      <Typography variant="body2">
        {`XXX-XX-${(params.value as string).slice(-4)}`}
      </Typography>
    ),
  },
  {
    field: "actions",
    headerName: "1099",
    width: 100,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <IconButton
        onClick={() => handlePdfDownload(params.row)}
        size="small"
        color="primary"
        disabled={params.row.pdfVisible === false}
      >
        <PictureAsPdfIcon />
      </IconButton>
    ),
  },
];
const currentYear = new Date().getFullYear();
function Payments() {
  const [year, setYear] = useState(currentYear);
  const [years, setYears] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState("payeeId");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const [checked, setChecked] = React.useState(false);

  const handleChangePDF = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pdfData: pdfavailable = {
      year: year,
      available: event.target.checked,
    };
    setChecked(event.target.checked);
    agent.Options.updateTaxPdfOption(pdfData);
  };

  async function getYears() {
    try {
      const response = await agent.Payments.getTaxYear();
      setYears(response);
    } finally {
    }
  }
  async function getPayments(selectedYear: string) {
    try {
      const response = await agent.Payments.getAllPaymentsByYear(
        selectedYear,
        page,
        rowsPerPage,
        orderBy,
        order,
        searchTerm
      );
      setData(response.data);
      setTotalCount(response.count);
      const response2 = await agent.Options.getTaxPdfOptionByYear(
        year.toString()
      );
      setChecked(response2);
    } finally {
      setLoading(false);
    }
  }
  const fetchDataAndDownloadCSV = async () => {
    setLoadingButton(true);
    const { data } = await agent.Payments.getAllPaymentsByYear(year.toString());
    const sanitizedData = data.map(
      ({ paymentStatus, pdfVisible, ...rest }: any) => rest
    );
    const headers = Object.keys(sanitizedData[0]).join(",");

    const rows = sanitizedData.map((row: any) =>
      Object.values(row)
        .map((value) => `"${value}"`)
        .join(",")
    );
    const csvContent = [headers, ...rows].join("\n");

    // Create a Blob and trigger the download
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `1099Payees${year}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setLoadingButton(false);
  };

  useEffect(() => {
    getYears();

    getPayments(year.toString());
  }, [page, rowsPerPage, searchTerm, year, checked]);

  const handleChange = (event: SelectChangeEvent) => {
    setYear(event.target.value as unknown as number);
    setLoading(true);
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPage(model.page);
    setRowsPerPage(model.pageSize);
    setLoading(true);
    getPayments(year.toString());
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length > 0) {
      setOrderBy(model[0].field);
      setOrder(model[0].sort as "asc" | "desc");
      setLoading(true);
      getPayments(year.toString());
    }
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <React.Fragment>
      <Helmet title="Payees" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Payments
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Payments</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={2}>
          <Paper p={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={years.includes(year) ? year.toString() : ""}
                label="Year"
                onChange={handleChange}
              >
                {years.map((yearOption) => (
                  <MenuItem key={yearOption} value={yearOption}>
                    {yearOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper p={2} mb={4}>
            <TextField
              label="Search Payments"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search by Payee ID, Name, Address and email"
            />
          </Paper>
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            variant="contained"
            color="secondary"
            sx={{ height: "56px" }}
            onClick={fetchDataAndDownloadCSV}
            loading={loadingButton}
          >
            Download 1099 Info
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={handleChangePDF}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={`Display 1099 for tax year ${year}`}
              />
            </FormGroup>
          </FormControl>
          <Paper p={2}>
            <Box sx={{ height: 620, width: "100%" }}>
              <DataGrid
                rows={data}
                loading={loading}
                columns={columns}
                rowCount={totalCount}
                paginationMode="server"
                sortingMode="server"
                pageSizeOptions={[50, 100, 200]}
                paginationModel={{
                  page,
                  pageSize: rowsPerPage,
                }}
                onPaginationModelChange={handlePaginationModelChange}
                onSortModelChange={handleSortModelChange}
                slots={{
                  noRowsOverlay: () => (
                    <div>No payments available for Tax year ending {year}</div>
                  ),
                }}
                initialState={{
                  pagination: {},
                }}
                disableRowSelectionOnClick
                sx={{
                  border: "none", // Remove border
                  "& .MuiDataGrid-columnsContainer": {
                    borderBottom: "1px solid #e0e0e0", // Optional: Add borderBottom to column header if needed
                  },
                  "& .MuiDataGrid-columnSeparator": {
                    display: "none", // Optional: Hide column separators
                  },
                }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Payments;
function handlePdfDownload(row: Payment1099): void {
  agent.Payments.download1099PDF(row)
    .then((response: Blob) => {
      const url = URL.createObjectURL(response);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${row.payeeName}_${row.year}_1099.pdf`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error downloading PDF:", error);
    });
}
