import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import { RemoveRedEye as RemoveRedEyeIcon } from "@mui/icons-material";
import {
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;
interface BillDataItem {
  programName: string;
  amount: number;
  taxable: boolean;
  status: string;
  requestingApplication: string;
  requestingClient: string;
  invoiceDate: string;
  paymentService: {
    billRefCode: string;
    paymentStatus: string;
    paymentRefCode: string;
  };
}

interface PayeeBillsProps {
  billData: BillDataItem[];
}

export default function PayeeBills(props: PayeeBillsProps) {
  const { billData } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Bills" {...a11yProps(0)} />
          {/* <Tab label="Item Two" {...a11yProps(1)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Paper>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Application</TableCell>
                  <TableCell>Client</TableCell>
                  <TableCell>Program</TableCell>
                  <TableCell>Invoice Date</TableCell>
                  <TableCell>Bill RefCode</TableCell>
                  <TableCell>Bill Status</TableCell>
                  <TableCell>Bill Amount</TableCell>
                  <TableCell>Taxable</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.requestingApplication}
                    </TableCell>
                    <TableCell>{row.requestingClient}</TableCell>
                    <TableCell>{row.programName}</TableCell>
                    <TableCell>
                      {new Intl.DateTimeFormat("en-US", {
                        dateStyle: "medium",
                      }).format(new Date(row.invoiceDate))}
                    </TableCell>
                    <TableCell>
                      {row.paymentService?.billRefCode || "N/A"}
                    </TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>
                      {row.amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </TableCell>
                    <TableCell>{row.taxable ? "Yes" : "No"}</TableCell>
                    <TableCell>
                      {row.paymentService?.paymentStatus || "N/A"}
                    </TableCell>
                    <TableCell padding="none" align="left">
                      <Box mr={2}>
                        <IconButton
                          aria-label="details"
                          component={NavLink}
                          to={`/bills/detail/${
                            row.paymentService?.billRefCode || "N/A"
                          }`}
                          size="large"
                          disabled={!row.paymentService?.billRefCode}
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </Paper>
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel> */}
    </Box>
  );
}
